/**
 * 入力の文字列に対して、リンクのみaタグに変換した文字列（HTML）を返す。
 * 出力された文字列はv-htmlで使用すること。
 * @param str 入力の文字列
 */
export function linkify(str: string | null | undefined): string {
  if (!str) {
    return ''
  }

  // テキスト中の<>やクオーテーションは、そのまま文字として出力したいのでエスケープ
  // memo: sanitize-htmlだとscriptタグなどを文字列としても消してしまうので自前実装した
  str = str.replace(/&/g, '&amp;')
  str = str.replace(/</g, '&lt;')
  str = str.replace(/>/g, '&gt;')
  str = str.replace(/"/g, '&quot;')
  str = str.replace(/'/g, '&#39;')

  // URLをAタグに変換
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi
  str = str.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>'
  })

  // \nを改行に変換
  str = str.replace(/\n/gi, '<br>')

  return str
}
